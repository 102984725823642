import classNames from "classnames"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import DocSummary from "../models/doc-summary.model"
import { getDocs } from "../services/doc.service"
import Template from "./template.component"

const Docs = (): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [docs, setDocs] = useState<DocSummary[]>([])
  const [categories, setCategories] = useState<string[]>([])

  useEffect((): void => {
    const init = async () => {
      try {
        const docs: DocSummary[] = await getDocs()
        setDocs(docs)
        setCategories(Array.from(new Set(docs.map(doc => doc.category))))
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Docs | Gee Linsky" description="Docs for Gee Linsky">
      <>
        {
          isLoading && 
          <div className="d-flex justify-content-center">
            <div className="spinner-grow">

            </div>
          </div>
        }
        {
          !isLoading &&
          <>
            <ul className="nav nav-tabs mb-2">
              {
                categories.map((category: string, i: number) => (
                  <li className="nav-item">
                    <button
                      className={classNames('nav-link', { active: i === activeTab })}
                      onClick={(): void => setActiveTab(i)}
                    >
                      {category}
                    </button>
                  </li>
                ))
              }
            </ul>
            <div className="row">
              {
                docs.filter(doc => doc.category === categories[activeTab]).map(doc => (
                  <div className="col-md-6 col-lg-4 text-truncate">
                    <h6>
                      <Link to={`/doc/${doc._id}`}>{doc.name}</Link>
                    </h6>
                  </div>
                ))
              }
            </div>
          </>
        }
      </>
    </Template>
  )
}

export default Docs
