import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './home.component'
import Resources from './resources.component'
import Resume from './resume.component'
import Docs from './docs.component'
import Doc from './doc.component'

const App = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/resume" element={<Resume />}></Route>
        <Route path="/resources" element={<Resources />}></Route>
        <Route path="/docs" element={<Docs />}></Route>
        <Route path="/doc/:id" element={<Doc />}></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App
