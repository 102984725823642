import ReactMarkdown from "react-markdown"

type Props = {
  source: string
}

const MarkdownComponent = (props: Props): JSX.Element => {
  return (
    <ReactMarkdown
      source={props.source}
      linkTarget="_blank"
    />
  )
}

export default MarkdownComponent
