import { useEffect, useState } from "react"
import { Link, useParams } from "react-router-dom"
import DocModel from "../models/doc.model"
import { getDoc, getDocs } from "../services/doc.service"
import MarkdownComponent from "./markdown.component"
import Template from "./template.component"

const Doc = (): JSX.Element => {

  const { id } = useParams<{ id: string | undefined }>()

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [doc, setDoc] = useState<DocModel>({ _id: '', name: '', content: '', category: '', public: false, user: '' })

  useEffect(() => {
    const init = async () => {
      try {
        const doc = await getDoc(id)
        setDoc(doc)
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Doc | Gee Linsky" description="Doc for Gee Linsky">
      <>
        {
          isLoading &&
          <>
            <div className="d-flex justify-content-center">
              <div className="spinner-grow">

              </div>
            </div>
          </>
        }
        {
          !isLoading &&
          <>
            <nav>
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><Link to="/docs">Docs</Link></li>
                <li className="breadcrumb-item active">{doc.name}</li>
              </ol>
            </nav>
            <MarkdownComponent source={doc.content} />
          </>
        }
      </>
    </Template>
  )
}

export default Doc
