import classNames from "classnames"
import { useEffect, useState } from "react"

import Resource from "../models/resource.model"
import { getResources } from "../services/resource.service"
import Template from "./template.component"

const Resources = (): JSX.Element => {

  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [activeTab, setActiveTab] = useState<number>(0)
  const [resources, setResources] = useState<Resource[]>([])
  const [categories, setCategories] = useState<string[]>([])

  useEffect((): void => {
    const init = async () => {
      try {
        const resources: Resource[] = await getResources()
        setResources(resources)
        setCategories(Array.from(new Set(resources.map(resource => resource.category))))
      } finally {
        setIsLoading(false)
      }
    }

    init()
  }, [])

  return (
    <Template title="Resources | Gee Linsky" description="Resources for Gee Linsky.">
      <>
        {
          isLoading && 
          <div className="d-flex justify-content-center">
            <div className="spinner-grow">

            </div>
          </div>
        }
        {
          !isLoading &&
          <>
            <ul className="nav nav-tabs mb-2">
              {
                categories.map((category: string, i: number) => (
                  <li className="nav-item">
                    <button
                      className={classNames('nav-link', { active: i === activeTab })}
                      onClick={(): void => setActiveTab(i)}
                    >
                      {category}
                    </button>
                  </li>
                ))
              }
            </ul>
            <div className="row">
              {
                resources.filter(resource => resource.category === categories[activeTab]).map(resource => (
                  <div className="col-md-6 col-lg-4 text-truncate">
                    <h6>
                      <a href={resource.url} target="_blank">
                        {resource.title}
                      </a>
                    </h6>
                  </div>
                ))
              }
            </div>
          </>
        }
      </>
    </Template>
  )
}

export default Resources
