import env from "./environment.service";
import Resource from "../models/resource.model";

const getResources = async (): Promise<Resource[]> => {
  const url: string = `${env.apiUrl}/resource/user/${env.userId}`
  const res = await fetch(url)
  return res.json()
}

export { getResources }
