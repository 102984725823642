import { useEffect } from 'react'
import { Helmet } from 'react-helmet'

import Navbar from "./navbar.component"

const Home = (): JSX.Element => {

  useEffect((): void => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <Helmet>
        <title>Gee Linsky</title>
        <meta name="description" content="Home for Gee Linsky." />
      </Helmet>
      <Navbar fixedTop={true} />
      <div
        style={{
          'backgroundImage': 'url(\'https://static.geelinsky.com/mountain.jpeg\')',
          'height': '100%',
          'backgroundPosition': 'center',
          'backgroundRepeat': 'no-repeat',
          'backgroundSize': 'cover'
        }}
      >
        <div
          style={{
            'backgroundColor': 'rgba(0, 0, 0, 0.6)',
            'height': '100%'
          }}
        >
          <div className="text-white d-flex flex-column justify-content-center align-items-center h-100">
            <h1 className="display-1">Gee Linsky</h1>
            <code>Solving problems one line at a time.</code>
          </div>
        </div>
      </div>
    </>
  )
}

export default Home
