import { Prism } from 'react-syntax-highlighter'
import { duotoneLight } from 'react-syntax-highlighter/dist/esm/styles/prism'

type Props = {
  code: string
  language: string
}

const SyntaxHighlighter = (props: Props): JSX.Element => (
  <Prism language={props.language} style={duotoneLight}>
    {props.code}
  </Prism>
)

export default SyntaxHighlighter
