import SyntaxHighlighter from "./syntax-highlighter.component"
import Template from "./template.component"

const Resume = (): JSX.Element => {

  const skills: string = 
`{
  "Languages": [
    "JavaScript",
    "TypeScript",
    "Java",
    "Python",
    "HTML",
    "CSS",
    "SCSS",
    "SQL"
  ],
  "JavaScript/TypeScript": [
    "React",
    "Express",
    "Passport",
    "Mongoose",
    "Angular",
    "Jasmine"
  ],
  "React": [
    "Create React App",
    "React Router",
    "Formik",
    "Docusaurus",
    "Material UI"
  ],
  "CSS": [
    "Bootstrap"
  ],
  "DevOps": [
    "Docker",
    "AWS"
  ]
}`

  return (
    <Template title="Resume | Gee Linsky" description="Resume for Gee Linsky.">
      <>
        <div className="text-center pb-4">
          <a href="https://static.geelinsky.com/Resume-Garrett_Polinsky.pdf" target="_blank" className="link-danger">
            <i className="fas fa-file-pdf fa-2x" />
          </a>
        </div>
        <div className="row">
          <div className="col-lg-6">
            <h1>Skills</h1>
            <SyntaxHighlighter code={skills} language="json"></SyntaxHighlighter>
            <h1>Education</h1>
            <h6><i>Texas State University</i> <small>Fall ‘18</small></h6>
            <h6>Bachelor of Science <small>Cum Laude</small></h6>
            <h6>Computer Science <small>Major</small></h6>
            <h6>Applied Mathematics <small>Minor</small></h6>
          </div>
          <div className="col-lg-6">
            <h1>Experience</h1>
            <h6><i>Open Lending</i></h6>
            <h6>Junior Software Engineer <small>Jul ‘19 - Present</small></h6>
            <ul>
              <li>Collaborate daily with R&D team to refactor insurance claims portion of the Java monolith into microservices with an Angular frontend.</li>
              <li>Created internal tool, LP Tools, to decrypt PII data for risk department, and to transfer data dynamically from one database to another.</li>
              <li>Document processes and procedures on Confluence on a regular basis.</li>
              <li>Assisted internal employees with technical issues as part of the IT support team.</li>
            </ul>
            <h1>Projects</h1>
            <h6>Static Websites</h6>
            <ul>
              <li>Produced static websites for various clients.</li>
              <li>Clients included Hanara Sushi, Bubble Believers, VerTraid, idontknowjeffery, and Very Necessary.</li>
            </ul>
            <h6>Personal Website</h6>
            <ul>
              <li>Develop and maintain a personal website for public and private use.</li>
              <li>Frontend composed of React, Create React App, React Router, Formik, Bootstrap, and more. Backend composed of Express, Passport, Mongoose, and more.</li>
              <li>Utilize MongoDB Atlas to set up MongoDB.</li>
              <li>Continuous delivery with GitHub actions.</li>
              <li>Deployed on various AWS services including EC2, S3, CloudFront, Route 53, and more.</li>
              <li>Domain resides on Google to utilize Google Workplace.</li>
            </ul>
          </div>
        </div>
      </>
    </Template>
  )
}

export default Resume
