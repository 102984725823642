type Environment = {
  readonly apiUrl: string
  readonly userId: string
}

const env: Environment = {
  apiUrl: String(process.env.REACT_APP_API_URL),
  userId: String(process.env.REACT_APP_USER_ID)
}

export default env
