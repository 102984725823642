import DocSummary from "../models/doc-summary.model";
import Doc from "../models/doc.model";
import env from "./environment.service";

const getDoc = async (id: string | undefined): Promise<Doc> => {
  const url: string = `${env.apiUrl}/doc/${id}/public`
  const res = await fetch(url)
  return res.json()
}

const getDocs = async (): Promise<DocSummary[]> => {
  const url: string = `${env.apiUrl}/doc/user/${env.userId}/public`
  const res = await fetch(url)
  return res.json()
}

export { getDoc, getDocs }
